<template>
    <div class="service-agreement-wrapper">
        <h2>超赞版权协议</h2>
        <div class="service-agreement-content">
            尊敬的广大用户，欢迎你注册试用超赞平台服务。本平台为北京代码澎湃科技有限公司所合法运营，超赞作为网络服务提供者，为了更好地服务广大用户，特别是为了广大用户在使用服务中降低版权侵权风险、保障用户及其他权利人的合法权益，特与广大用户签订本协议，供广大用户与超赞共同遵照执行。
            <div>你应当在注册本平台后仔细阅读并充分理解本协议条款内容，特别是免除或限制责任的相应条款，你应重点阅读。若你对其中的条款有疑问或不同意其中任何一处条款内容的，你应当终止你的购买行为，否则视为你同意本协议所有条款，并严格遵守。</div>
        </div>
        <div class="service-agreement-title">
            一、关于版权分类
        </div>
        <div class="service-agreement-content">
            本平台所涉及的版权主要为（1）产品模板；（2）及供你选择应用于产品中的其他素材（主要为字体、图片等）；（3）你通过委托超赞或在本平台提供服务的第三方创作而拥有的符合著作权法的设计作品。此外的其他类型版权仍适用本协议，并应当作出符合本协议全文的解释。        </div>
        <div class="service-agreement-title">
            二、版权来源及特殊标识（针对产品模板、应用于产品中的其他素材）
        </div>
        <div class="service-agreement-content">
            1、第一类来源：超赞原创设计，模板或素材中标有<img src="../../assets/manager-imgs/agreement.png" />；
        </div>
        <div class="service-agreement-content">
            2、第二类来源：第三方提供，模板或素材中标有<img src="../../assets/manager-imgs/agreement.png" />；
        </div>
        <div class="service-agreement-content">
            3、第三类来源：网友共享，模板或素材中标有<img src="../../assets/manager-imgs/agreement1.png" />。
        </div>
        <div class="service-agreement-title">
            三、使用场景
        </div>
        <div class="service-agreement-content">
            1、对于本平台提供的任何模板、素材，你保证仅限于在本平台提供的应用场景下使用，不得将素材通过复制、修改、截图、拍照等形式应用到本平台提供的应用场景之外的其他场景，否则造成的一切责任由你自行承担。
        </div>
        <div class="service-agreement-content">
            2、对于通过委托超赞或在本平台提供服务的第三方创作而拥有的符合著作权法的设计作品，你享有非独占的永久使用权。
        </div>
        <div class="service-agreement-title">
            四、使用范围及责任承担
        </div>
        <div class="service-agreement-content">
            （一）针对产品模板、应用于产品中的其他素材
            <div class="service-agreement-indent">
                1、对于超赞原创模板或素材，超赞保证享有完整的知识产权，你可在本平台提供的应用场景中进行商业使用。若因该些模板、素材侵犯他人合法权益造成你被权利人索赔的，该赔偿金额在合理的范围内由超赞向你承担，<u><strong>但你完全同意并确认超赞承担责任的最大限额为：不超过人民币1万元整。</strong></u>
            </div>
            <div class="service-agreement-indent">
                2、对于第三方提供的模板或素材，因第三方已向本平台做出版权承诺，在本平台未提示你需单独向第三方获得授权的情况下，你可将该些模板、素材在本平台提供的应用场景下进行商业使用。<u><strong>若因该些模板、素材侵犯他人合法权益造成你被权利人追责的，你应当自行要求第三方承担责任，超赞作为平台方，将在能力范围内协助你向第三方追责。</strong></u>
            </div>
        </div>
        <div class="service-agreement-content">
            对于第三方提供的模板或素材，本平台已提示你需要获得第三方授权才可使用的，你应当向第三方取得授权，否则你将可能侵害第三方或其他知识产权人的合法权利，由此造成的纠纷，你应当自行解决，与超赞无关。若因你侵害第三人的权利导致超赞向第三人承担责任的，超赞有权向你追偿。
        </div>
        <div class="service-agreement-content"><u><strong>3、对于网友共享的模板或素材，本平台无法保证其版权归属、网友亦未向本平台做出任何承诺，因此你应当自行审慎甄别是否用于商业用途。若你使用该些模板或素材造成被权利人追责的，你应当自行解决并独立承担责任，与超赞无关。</strong></u></div>
        <div class="service-agreement-content"><u><strong>4、如你使用的模板或素材未显示本协议第二条约定的特殊标识，则你可在本平台提供的应用场景中进行商业使用。</strong></u></div>
        <div class="service-agreement-content"><u><strong>5、你理解，本平台不提供字体源文件下载服务，如你需要下载字体源文件到本地使用，则需自行联系字体权利人取得授权，否则因此产生的所有纠纷由你自行承担。</strong></u></div>
        <div class="service-agreement-content">
            （二）针对通过超赞或第三方创作而拥有的设计作品
            <div class="service-agreement-indent">
                <u><strong>1、对于你通过委托超赞创作而拥有的设计作品，超赞保证享有完整的知识产权，若该些设计作品侵犯他人合法权益造成你被权利人索赔的，该赔偿金额在合理的范围内由超赞向你承担，但你完全同意并确认超赞承担责任的最大限额为：不超过人民币1万元整。</strong></u>
            </div>
            <div class="service-agreement-indent">
                <u><strong>2、对于你通过委托在本平台提供服务的第三方创作而拥有的设计作品，若该设计作品侵犯他人合法权益造成你被权利人追责的，你应当自行要求第三方承担责任，超赞作为平台方，将在能力范围内协助你向第三方追责。</strong></u>
            </div>
        </div>
        <div class="service-agreement-title">
            五、违约责任
        </div>
        <div class="service-agreement-content">
            1、因你违反本协议条款给本平台造成不良影响的，超赞有权终止为你提供服务、断开你在本平台的服务链接、不予退还你支付的服务费用。
        </div>
        <div class="service-agreement-content">
            2、因你违反本协议条款给超赞造成损失的，超赞有权向你追偿，追偿金额包括超赞的实际损失、超赞为向你追偿而支出的合理费用（包括但不限于律师费、诉讼费、公证费、交通费等）。
        </div>
        <div class="service-agreement-title">
            六、免责条款
        </div>
        <div class="service-agreement-content">
            <u><strong>1、你在使用本平台模板或素材后，遭到任何第三方向你提出版权争议的，你应当立即通过有效的方式告知超赞，由超赞协助处理，否则造成进一步损失的，由你自行承担，超赞将免责。</strong></u>
        </div>
        <div class="service-agreement-content">
            <u><strong>2、对于本协议所述需要超赞承担责任的，超赞不承担任何惩罚性赔偿责任，不承担特别的、间接的、期待的、连带的费用，同时，当你的合理损失超过超赞所需承担费用上限（人民币1万元）时，超过部分超赞无需承担。</strong></u>
        </div>
        <div class="service-agreement-content">
            3、超赞作为网络服务提供者，对于版权归属问题无资质调查、亦无充分的监控能力，若平台中出现超赞原创作品以外的其他作品侵犯你的合法权益的，超赞无需承担责任。你可通过本平台公布的邮箱、地址、电话等联系方式，向超赞发出书面通知，要求超赞进行处理。超赞在处理过程中，有权要求你进一步提供相关的证明文件，若你怠于提供相关证明文件的，超赞有权不予处理，造成的后果由你自行承担。
        </div>
        <div class="service-agreement-title">
            七、其他
        </div>
        <div class="service-agreement-content">
            1、本协议的生效、解释、执行及争议解决等均适用中华人民共和国法律。
        </div>
        <div class="service-agreement-content">
            2、因本协议履行中发生的争议，你应当与超赞协商解决。协商不成的，任何一方均可向北京市海淀区人民法院提起诉讼。
        </div>
        <div class="service-agreement-content">
            <u><strong>3、本协议自公布在本平台上时生效。</strong></u>
        </div>
        <div class="service-agreement-content"><strong>以上声明，你可随时在超赞平台阅读、复制或下载。</strong></div>
    </div>
</template>

<script>
    export default {
        name: "ServiceAgreement"
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index";

</style>
